import {globalState} from "./mx-demo-vue.js";


const { launchModalForm } = await import("../marketo/form-modal-launcher.js");

// The Demo Form
$(".js-launchMxDemoForm").on("click", async function() {

	const widgetData = {
		widget: {
			name: "Demo Request Form",
			variation: "Mx Interactive Demo",
			instanceId: "",
		},
		setup: {
			type: "ContactForm",
			form: {
				name: "Demo Request Blade",
			},
			tracking: {
				usePageOfferId: false,
				offerId: "7013x000001yAzlAAE",
			}
		}
	};

	const { componentInstance } = await launchModalForm({
		formSettingsKey: "demo-request",
		widgetData: widgetData,
		isInModal: true,
		customData:{
			disableFormHeading: false,
			ctaButtonText: "Schedule Your Demo",
			formHeading: "Let's get your demo scheduled!",
		}
	});

	componentInstance.onEvent("completed", () => {
		globalState.value = true;
	});
});
